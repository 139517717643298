<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-7
          .text-center.m-4
            img(src="/img/register/status_02.png")
          h1 ユーザー情報入力
          p ユーザー登録が完了しました。続けてユーザー情報を入力して下さい。
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit="exec(true)" ref="form" method="POST" novalidate)
            .form-group
              label(for="nickname") お名前（サイト内の表示用 最大20文字まで）
              input#nickname.form-control(type="text" placeholder="お名前" v-model="nickname" maxlength='20')
            .form-group
              label 性別
              br
              .form-check.form-check-inline
                input#gender1.form-check-input(type="radio" v-model="gender" name="gender" value="男性" ref="gender" required)
                label.form-check-label.radio-label(for="gender1") 男性
              .form-check.form-check-inline
                input#gender2.form-check-input(type="radio" v-model="gender" name="gender" value="女性" ref="gender" required)
                label.form-check-label.radio-label(for="gender2") 女性
              .form-check.form-check-inline
                input#gender3.form-check-input(type="radio" v-model="gender" name="gender" value="未回答" ref="gender" required checked="checked")
                label.form-check-label.radio-label(for="gender3") 未回答
            #genderError.invalid-feedback 性別を選択してください
            .form-group
              label 年代
              Age(v-model="age")
              .invalid-feedback 年代を選択してください
            .form-group
              label 都道府県
              Pref(v-model="locale")
              .invalid-feedback お住まいの都道府県を選択してください
            .form-group
              div(for="isPrivate") ユーザー情報公開
                span.css-fukidashi
                  .text.mx-3.px-1(@mouseover="show1 = true" @mouseleave="show1 = false") ？
                  transition(name="fade")
                    p.fukidashi(v-if="show1") 名前、登攀記録、チェックイン状況等の他ユーザーへの公開有無設定
            .custom-control.custom-checkbox
              input#isPrivate.custom-control-input(type="checkbox" v-model="isPrivate")
              label.custom-control-label(for="isPrivate") ユーザー情報を公開する
            .form-group.ml-2
              .mt-3.ml-1(for="recordOpenRange") 登攀記録の公開範囲
                span.css-fukidashi
                  .text.mx-3.px-1(@mouseover="show2 = true" @mouseleave="show2 = false") ？
                  transition(name="fade")
                    p.fukidashi(v-if="show2") 登攀記録(登攀件数、どの岩場を登攀したか)の公開範囲設定
              .form-check.form-check-inline.mt-2.ml-2
                input#recordOpenRange1.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="OPEN" required) 
                label.form-check-label.radio-label(for="recordOpenRange1") 公開 
              br
              .form-check.form-check-inline.ml-2
                input#recordOpenRange2.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="FRIENDS_ONLY" required)
                label.form-check-label.radio-label(for="recordOpenRange2") 友達のみ公開
              br
              .form-check.form-check-inline.mb-2.ml-2
                input#recordOpenRange3.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="CLOSE" required)
                label.form-check-label.radio-label(for="recordOpenRange3") 非公開
            .form-group.ml-2
              div.mt-3.ml-1(for="checkinOpenRange") チェックイン情報の公開範囲
                span.css-fukidashi
                  div.text.mx-3.px-1(@mouseover="show3 = true" @mouseleave="show3 = false") ？
                  transition(name="fade")
                    p.fukidashi.mt-1(v-if="show3") チェックイン情報（リアルタイム情報と履歴情報）の公開範囲設定
              .form-check.form-check-inline.mt-2.ml-2
                input#checkinOpenRange1.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="OPEN" required) 
                label.form-check-label.radio-label(for="checkinOpenRange1") 公開 
              br
              .form-check.form-check-inline.ml-2
                input#checkinOpenRange2.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="FRIENDS_ONLY" required)
                label.form-check-label.radio-label(for="checkinOpenRange2") 友達のみ公開
              br
              .form-check.form-check-inline.ml-2
                input#checkinOpenRange3.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="CLOSE" required)
                label.form-check-label.radio-label(for="checkinOpenRange3") 非公開
            .form-group
              label(for="Inquiry") 自己紹介
              textarea#Inquiry.form-control(type="Inquiry" rows="5" maxlength="150" v-model="profileText")
            .form-group
              label 有料プランにされる方は決済方法をお選びください
              br
              .form-check.pl-0
                input#payMethod1.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="credit" )
                label.form-check-label.radio-label(for="payMethod1") カード
              .form-check.pl-0
                input#payMethod2.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="docomo" )
                label.form-check-label.radio-label(for="payMethod2") docomo
              .form-check.pl-0
              //-   input#payMethod3.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="auone" )
              //-   label.form-check-label.radio-label(for="payMethod3") au
              .form-check.pl-0
                input#payMethod4.form-check-input(type="radio" v-model="payMethod" name="payMethod" value="softbank2" )
                label.form-check-label.radio-label(for="payMethod4") SoftBank
            .form-group
              input.btn.btn-primary(type="submit" value="支払い方法登録へ" v-bind:disabled="isDisabled")
              br
              input.btn.btn-secondary.mt-3(type="button" value="無料プランで利用する" @click.prevent="exec(false)" v-bind:disabled="isDisabled") 
            input(type="hidden" name="pay_method" ref="payMethodHidden")
            input(type="hidden" name="merchant_id" value="74522")
            input(type="hidden" name="service_id" value="001")
            input(type="hidden" name="cust_code" ref="userId")
            input(type="hidden" name="order_id" ref="orderId")
            input(type="hidden" name="item_id" ref="itemId")
            input(type="hidden" name="item_name" ref="itemName")
            input(type="hidden" name="service_type" ref="serviceType")
            input(type="hidden" name="terminal_type" ref="terminalType")
            input(type="hidden" name="success_url" ref="successUrl")
            input(type="hidden" name="cancel_url" ref="cancelUrl")
            input(type="hidden" name="error_url" ref="errorUrl")
            input(type="hidden" name="pagecon_url" ref="pageconUrl")
            input(type="hidden" name="request_date" ref="requestDate")
            input(type="hidden" name="limit_second" value="600")
            input(type="hidden" name="sps_hashcode" ref="hashcode")
</template>

<script>
import Pref from '@/components/user/pref.vue'
import Age from '@/components/user/age.vue'
import { createHash } from 'crypto'
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      nickname: '未設定',
      mail: this.$route.params.mail,
      password: this.$route.params.password,
      gender: '未回答',
      age: '未回答',
      locale: '未回答',
      message: '',
      isDisabled: false,
      payMethod: '',
      isPrivate: true,
      checkinOpenRange: 'OPEN',
      recordOpenRange: 'OPEN',
      show1: false,
      show2: false,
      show3: false,
      errMessage: false,
      profileText:''
    }
  },
  mounted() {
    //URL設定
    this.$refs.successUrl.value = location.origin + '/register_user/complete'
    this.$refs.cancelUrl.value = location.origin + '/register_user/complete_free'
    this.$refs.errorUrl.value = location.origin + '/'
    this.$refs.pageconUrl.value = process.env.VUE_APP_API_GATEWAY + '/api-pay'

    
  },
  methods: {
    update(isPay) {
      //属性の設定
      let attributeList = []

      let dataNickname = {
        Name : 'nickname',
        //空の場合'未設定'
        Value : this.nickname == '' ? '未設定' : this.nickname
      }
      let attributeNickname = new CognitoUserAttribute(dataNickname)
      attributeList.push(attributeNickname)

      let dataGender = {
        Name : 'gender',
        Value : this.gender
      }
      let attributeGender = new CognitoUserAttribute(dataGender)
      attributeList.push(attributeGender)

      let dataAge = {
        Name : 'custom:age',
        Value : this.age
      }
      let attributeAge = new CognitoUserAttribute(dataAge)
      attributeList.push(attributeAge)

      let dataLocale = {
        Name : 'locale',
        Value : this.locale
      }
      let attributeLocale = new CognitoUserAttribute(dataLocale)
      attributeList.push(attributeLocale)

      //属性の更新
      this.$user.cognito.updateAttributes(attributeList, (err, result) => {
        if(err) {
          this.errMessage = true
          this.isDisabled = false
          return
        }
        if(!this.isPrivate) {
          this.checkinOpenRange = "CLOSE"
          this.recordOpenRange = "CLOSE"
        }
        //公開有無とチェックイン公開範囲の更新
        const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
        const params = {
          isPrivate: !this.isPrivate,
          checkinOpenRange: this.checkinOpenRange,
          recordOpenRange: this.recordOpenRange,
          name: dataNickname.Value,
          gender: this.gender,
          age: this.age,
          locale: this.locale,
          profileText: this.profileText,
        }
        //内容送信
        axios.patch(url,params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
          .then(res => {
            //ページ遷移
            this.$user.cognito.refreshSession(this.$user.refreshToken, (err, result) => {
              if(!isPay) {
                this.$router.push('/register_user/complete_free')
                this.isDisabled = false
                return
              }else if(err) {
                this.errMessage = true
                this.isDisabled = false
                return
              }
            })
          })
          .catch(err => {
            console.log(err)
          })
        })
    },
    exec(isPay) {
      this.isDisabled = true

      this.messages = ''

      this.errMessage = false

      //スペース削除処理
      this.nickname = this.nickname.trim()

      //エラー処理
      if (this.$refs.form.checkValidity() === false) {
        this.$refs.form.classList.add('was-validated')
        this.isDisabled = false
        return
      }

      //日付取得
      let date = new Date () 

      let year = date.getFullYear() 	// 年
      let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
      let day = ('0' + date.getDate()).slice(-2) //日
      let hour = ('0' + date.getHours()).slice(-2) 	// 時
      let minute = ('0' + date.getMinutes()).slice(-2)	// 分
      let second = ('0' + date.getSeconds()).slice(-2) 	// 秒

      this.$refs.requestDate.value = '' + year + month + day + hour + minute + second

      //クレジット決済の場合
      if(this.payMethod == 'credit') {
        this.$refs.orderId.value = ''
        this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT
        this.$refs.itemId.value = ''
        this.$refs.itemName.value = ''
        this.$refs.serviceType.value = ''
        this.$refs.terminalType.value = '0'
      }else {
        //キャリア決済の場合
        this.$refs.orderId.value = '' + year + month + day + hour + minute + second
        this.$refs.form.action = process.env.VUE_APP_PAY_CARRIER
        this.$refs.itemId.value = '001'
        this.$refs.itemName.value = 'plan1'
        this.$refs.serviceType.value = '0'
        this.$refs.terminalType.value = '0'
      }

      //Value設定
      this.$refs.payMethodHidden.value = this.payMethod
      this.$refs.userId.value = this.$user.userId

      // ハッシュ値設定
      this.createCheckHash(this.$refs.form)

      //属性更新
      this.update(isPay)
    },
    //ハッシュ値生成
    createCheckHash(e) {
      const hash = process.env.VUE_APP_PAY_HASH 
      let str = ''
      e.children.forEach(elm => {
        if (elm.type !== 'hidden') return
        str += elm.value
      })
      let shasum = createHash('sha1')
      shasum.update(str + hash)
      this.$refs.hashcode.value = shasum.digest('hex')
    },
  },
  components: {
    Pref,
    Age,
  },
}
</script>

<style lang="stylus" scoped>
.css-fukidashi
  padding 0
  margin 0
  position relative
  display inline-block
.text
  background #107dc7
  color #FFF
  position: relative
.fukidashi
  width 200px
  position absolute
  top -95px
  left -105px
  padding 5px
  border-radius 5px
  background #107dc7
  color #fff
  font-weight bold
  &:after
    position: absolute
    width 20px
    height 0
    left 123px
    bottom -19px
    margin-left 0px
    border solid transparent
    border-color rgba(51, 204, 153, 0)
    border-top-color #107dc7
    border-width 10px
    pointer-events none
    content " "
.fade-enter-active, .fade-leave-active 
  transition opacity .5s
.fade-enter, .fade-leave-to 
  opacity 0
</style>