var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-7" }, [
          _vm._m(0),
          _c("h1", [_vm._v("ユーザー情報入力")]),
          _c("p", [
            _vm._v(
              "ユーザー登録が完了しました。続けてユーザー情報を入力して下さい。"
            )
          ]),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message))
              ])
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "needs-validation",
              attrs: { method: "POST", novalidate: "" },
              on: {
                submit: function($event) {
                  return _vm.exec(true)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "nickname" } }, [
                  _vm._v("お名前（サイト内の表示用 最大20文字まで）")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nickname,
                      expression: "nickname"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "nickname",
                    type: "text",
                    placeholder: "お名前",
                    maxlength: "20"
                  },
                  domProps: { value: _vm.nickname },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.nickname = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("性別")]),
                _c("br"),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gender,
                        expression: "gender"
                      }
                    ],
                    ref: "gender",
                    staticClass: "form-check-input",
                    attrs: {
                      id: "gender1",
                      type: "radio",
                      name: "gender",
                      value: "男性",
                      required: ""
                    },
                    domProps: { checked: _vm._q(_vm.gender, "男性") },
                    on: {
                      change: function($event) {
                        _vm.gender = "男性"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "gender1" }
                    },
                    [_vm._v("男性")]
                  )
                ]),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gender,
                        expression: "gender"
                      }
                    ],
                    ref: "gender",
                    staticClass: "form-check-input",
                    attrs: {
                      id: "gender2",
                      type: "radio",
                      name: "gender",
                      value: "女性",
                      required: ""
                    },
                    domProps: { checked: _vm._q(_vm.gender, "女性") },
                    on: {
                      change: function($event) {
                        _vm.gender = "女性"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "gender2" }
                    },
                    [_vm._v("女性")]
                  )
                ]),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.gender,
                        expression: "gender"
                      }
                    ],
                    ref: "gender",
                    staticClass: "form-check-input",
                    attrs: {
                      id: "gender3",
                      type: "radio",
                      name: "gender",
                      value: "未回答",
                      required: "",
                      checked: "checked"
                    },
                    domProps: { checked: _vm._q(_vm.gender, "未回答") },
                    on: {
                      change: function($event) {
                        _vm.gender = "未回答"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "gender3" }
                    },
                    [_vm._v("未回答")]
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "invalid-feedback",
                  attrs: { id: "genderError" }
                },
                [_vm._v("性別を選択してください")]
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("年代")]),
                  _c("Age", {
                    model: {
                      value: _vm.age,
                      callback: function($$v) {
                        _vm.age = $$v
                      },
                      expression: "age"
                    }
                  }),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("年代を選択してください")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("都道府県")]),
                  _c("Pref", {
                    model: {
                      value: _vm.locale,
                      callback: function($$v) {
                        _vm.locale = $$v
                      },
                      expression: "locale"
                    }
                  }),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("お住まいの都道府県を選択してください")
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { attrs: { for: "isPrivate" } }, [
                  _vm._v("ユーザー情報公開"),
                  _c(
                    "span",
                    { staticClass: "css-fukidashi" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text mx-3 px-1",
                          on: {
                            mouseover: function($event) {
                              _vm.show1 = true
                            },
                            mouseleave: function($event) {
                              _vm.show1 = false
                            }
                          }
                        },
                        [_vm._v("？")]
                      ),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.show1
                          ? _c("p", { staticClass: "fukidashi" }, [
                              _vm._v(
                                "名前、登攀記録、チェックイン状況等の他ユーザーへの公開有無設定"
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isPrivate,
                      expression: "isPrivate"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: { id: "isPrivate", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isPrivate)
                      ? _vm._i(_vm.isPrivate, null) > -1
                      : _vm.isPrivate
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.isPrivate,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isPrivate = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isPrivate = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isPrivate = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "isPrivate" }
                  },
                  [_vm._v("ユーザー情報を公開する")]
                )
              ]),
              _c("div", { staticClass: "form-group ml-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "mt-3 ml-1",
                    attrs: { for: "recordOpenRange" }
                  },
                  [
                    _vm._v("登攀記録の公開範囲"),
                    _c(
                      "span",
                      { staticClass: "css-fukidashi" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text mx-3 px-1",
                            on: {
                              mouseover: function($event) {
                                _vm.show2 = true
                              },
                              mouseleave: function($event) {
                                _vm.show2 = false
                              }
                            }
                          },
                          [_vm._v("？")]
                        ),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.show2
                            ? _c("p", { staticClass: "fukidashi" }, [
                                _vm._v(
                                  "登攀記録(登攀件数、どの岩場を登攀したか)の公開範囲設定"
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline mt-2 ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recordOpenRange,
                          expression: "recordOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "recordOpenRange1",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "recordOpenRange",
                        value: "OPEN",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.recordOpenRange, "OPEN")
                      },
                      on: {
                        change: function($event) {
                          _vm.recordOpenRange = "OPEN"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "recordOpenRange1" }
                      },
                      [_vm._v("公開 ")]
                    )
                  ]
                ),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recordOpenRange,
                          expression: "recordOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "recordOpenRange2",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "recordOpenRange",
                        value: "FRIENDS_ONLY",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.recordOpenRange, "FRIENDS_ONLY")
                      },
                      on: {
                        change: function($event) {
                          _vm.recordOpenRange = "FRIENDS_ONLY"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "recordOpenRange2" }
                      },
                      [_vm._v("友達のみ公開")]
                    )
                  ]
                ),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline mb-2 ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recordOpenRange,
                          expression: "recordOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "recordOpenRange3",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "recordOpenRange",
                        value: "CLOSE",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.recordOpenRange, "CLOSE")
                      },
                      on: {
                        change: function($event) {
                          _vm.recordOpenRange = "CLOSE"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "recordOpenRange3" }
                      },
                      [_vm._v("非公開")]
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "form-group ml-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "mt-3 ml-1",
                    attrs: { for: "checkinOpenRange" }
                  },
                  [
                    _vm._v("チェックイン情報の公開範囲"),
                    _c(
                      "span",
                      { staticClass: "css-fukidashi" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text mx-3 px-1",
                            on: {
                              mouseover: function($event) {
                                _vm.show3 = true
                              },
                              mouseleave: function($event) {
                                _vm.show3 = false
                              }
                            }
                          },
                          [_vm._v("？")]
                        ),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.show3
                            ? _c("p", { staticClass: "fukidashi mt-1" }, [
                                _vm._v(
                                  "チェックイン情報（リアルタイム情報と履歴情報）の公開範囲設定"
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline mt-2 ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkinOpenRange,
                          expression: "checkinOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "checkinOpenRange1",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "checkinOpenRange",
                        value: "OPEN",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.checkinOpenRange, "OPEN")
                      },
                      on: {
                        change: function($event) {
                          _vm.checkinOpenRange = "OPEN"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "checkinOpenRange1" }
                      },
                      [_vm._v("公開 ")]
                    )
                  ]
                ),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkinOpenRange,
                          expression: "checkinOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "checkinOpenRange2",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "checkinOpenRange",
                        value: "FRIENDS_ONLY",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.checkinOpenRange, "FRIENDS_ONLY")
                      },
                      on: {
                        change: function($event) {
                          _vm.checkinOpenRange = "FRIENDS_ONLY"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "checkinOpenRange2" }
                      },
                      [_vm._v("友達のみ公開")]
                    )
                  ]
                ),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "form-check form-check-inline ml-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkinOpenRange,
                          expression: "checkinOpenRange"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "checkinOpenRange3",
                        type: "radio",
                        disabled: !_vm.isPrivate,
                        name: "checkinOpenRange",
                        value: "CLOSE",
                        required: ""
                      },
                      domProps: {
                        checked: _vm._q(_vm.checkinOpenRange, "CLOSE")
                      },
                      on: {
                        change: function($event) {
                          _vm.checkinOpenRange = "CLOSE"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "checkinOpenRange3" }
                      },
                      [_vm._v("非公開")]
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "Inquiry" } }, [
                  _vm._v("自己紹介")
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profileText,
                      expression: "profileText"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "Inquiry",
                    type: "Inquiry",
                    rows: "5",
                    maxlength: "150"
                  },
                  domProps: { value: _vm.profileText },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.profileText = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [
                  _vm._v("有料プランにされる方は決済方法をお選びください")
                ]),
                _c("br"),
                _c("div", { staticClass: "form-check pl-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payMethod,
                        expression: "payMethod"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: "payMethod1",
                      type: "radio",
                      name: "payMethod",
                      value: "credit"
                    },
                    domProps: { checked: _vm._q(_vm.payMethod, "credit") },
                    on: {
                      change: function($event) {
                        _vm.payMethod = "credit"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "payMethod1" }
                    },
                    [_vm._v("カード")]
                  )
                ]),
                _c("div", { staticClass: "form-check pl-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payMethod,
                        expression: "payMethod"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: "payMethod2",
                      type: "radio",
                      name: "payMethod",
                      value: "docomo"
                    },
                    domProps: { checked: _vm._q(_vm.payMethod, "docomo") },
                    on: {
                      change: function($event) {
                        _vm.payMethod = "docomo"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "payMethod2" }
                    },
                    [_vm._v("docomo")]
                  )
                ]),
                _c("div", { staticClass: "form-check pl-0" }),
                _c("div", { staticClass: "form-check pl-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payMethod,
                        expression: "payMethod"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: "payMethod4",
                      type: "radio",
                      name: "payMethod",
                      value: "softbank2"
                    },
                    domProps: { checked: _vm._q(_vm.payMethod, "softbank2") },
                    on: {
                      change: function($event) {
                        _vm.payMethod = "softbank2"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "payMethod4" }
                    },
                    [_vm._v("SoftBank")]
                  )
                ])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "submit",
                    value: "支払い方法登録へ",
                    disabled: _vm.isDisabled
                  }
                }),
                _c("br"),
                _c("input", {
                  staticClass: "btn btn-secondary mt-3",
                  attrs: {
                    type: "button",
                    value: "無料プランで利用する",
                    disabled: _vm.isDisabled
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.exec(false)
                    }
                  }
                })
              ]),
              _c("input", {
                ref: "payMethodHidden",
                attrs: { type: "hidden", name: "pay_method" }
              }),
              _c("input", {
                attrs: { type: "hidden", name: "merchant_id", value: "74522" }
              }),
              _c("input", {
                attrs: { type: "hidden", name: "service_id", value: "001" }
              }),
              _c("input", {
                ref: "userId",
                attrs: { type: "hidden", name: "cust_code" }
              }),
              _c("input", {
                ref: "orderId",
                attrs: { type: "hidden", name: "order_id" }
              }),
              _c("input", {
                ref: "itemId",
                attrs: { type: "hidden", name: "item_id" }
              }),
              _c("input", {
                ref: "itemName",
                attrs: { type: "hidden", name: "item_name" }
              }),
              _c("input", {
                ref: "serviceType",
                attrs: { type: "hidden", name: "service_type" }
              }),
              _c("input", {
                ref: "terminalType",
                attrs: { type: "hidden", name: "terminal_type" }
              }),
              _c("input", {
                ref: "successUrl",
                attrs: { type: "hidden", name: "success_url" }
              }),
              _c("input", {
                ref: "cancelUrl",
                attrs: { type: "hidden", name: "cancel_url" }
              }),
              _c("input", {
                ref: "errorUrl",
                attrs: { type: "hidden", name: "error_url" }
              }),
              _c("input", {
                ref: "pageconUrl",
                attrs: { type: "hidden", name: "pagecon_url" }
              }),
              _c("input", {
                ref: "requestDate",
                attrs: { type: "hidden", name: "request_date" }
              }),
              _c("input", {
                attrs: { type: "hidden", name: "limit_second", value: "600" }
              }),
              _c("input", {
                ref: "hashcode",
                attrs: { type: "hidden", name: "sps_hashcode" }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center m-4" }, [
      _c("img", { attrs: { src: "/img/register/status_02.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }